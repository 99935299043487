import WebFont from "webfontloader";

import "./app.less";

import Logo32 from "./assets/logo-32.png";
import Logo64 from "./assets/logo-64.png";
import Logo128 from "./assets/logo-128.png";
import NationalEmblem from "./assets/national_emblem.png";

import Icon500px from "./social/500px.svg";
import IconFacebook from "./social/facebook.svg";
import IconGithub from "./social/github.svg";
import IconGithubAlt from "./social/github-alt.svg";
import IconLinkedin from "./social/linkedin.svg";
import IconSteam from "./social/steam.svg";
import IconWechat from "./social/wechat.svg";

import WechatQRCode from "./social/wechat-qr.png";

var socialLinks = [
  {title: 'Github Profile', url: 'https://github.com/tjumyk', icon: IconGithub},
  {title: 'Github Homepage', url: 'https://tjumyk.github.io', icon: IconGithubAlt},
  {title: 'Linkedin', url: 'https://www.linkedin.com/in/yukaimiao', icon: IconLinkedin},
  {title: 'Facebook', url: 'https://www.facebook.com/yukai.miao', icon: IconFacebook},
  {
    title: 'Wechat',
    url: '#',
    icon: IconWechat, action: function () {
      var qrcodeElement = document.querySelector('.qrcode');
      if (!qrcodeElement.firstElementChild) { // add image on demand
        var qrImage = new Image();
        qrImage.src = WechatQRCode;
        qrcodeElement.appendChild(qrImage);
      }

      qrcodeElement.classList.add('show');
      setTimeout(function () {
        qrcodeElement.classList.add('shown')
      });
    }
  },
  {title: '500px', url: 'https://500px.com/tjumyk', icon: Icon500px},
  {title: 'Steam', url: 'https://steamcommunity.com/profiles/76561198174170822', icon: IconSteam}
];

function setupWebFonts() {
  WebFont.load({
    custom: {
      families: ['liukai', 'allura']
    },
    active: function () {
      document.body.classList.add('show');
    }
  });
}

function setupSiteIcons() {
  var iconLink = document.createElement('link');
  iconLink.rel = 'icon';
  iconLink.href = Logo32;
  iconLink.type = 'image/png';
  if (iconLink.sizes)
    iconLink.sizes.add('32x32');
  document.head.appendChild(iconLink);

  iconLink = document.createElement('link');
  iconLink.rel = 'icon';
  iconLink.href = Logo64;
  iconLink.type = 'image/png';
  if(iconLink.sizes)
    iconLink.sizes.add('64x64');
  document.head.appendChild(iconLink);

  iconLink = document.createElement('link');
  iconLink.rel = 'icon';
  iconLink.href = Logo128;
  iconLink.type = 'image/png';
  if(iconLink.sizes)
    iconLink.sizes.add('128x128');
  document.head.appendChild(iconLink);
}

function setupQRCode() {
  var qrCode = document.createElement('div');
  qrCode.classList.add('qrcode');
  qrCode.addEventListener('click', function () {
    qrCode.classList.remove('shown');
    setTimeout(function () {
      qrCode.classList.remove('show');
    }, 300);
  })
  document.body.insertAdjacentElement("afterbegin", qrCode);
}

function setupMainContent() {
  var ele = document.createElement('div');
  ele.classList.add('footer');
  ele.classList.add('fade-in')
  ele.innerHTML = `<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32102302010258" target="_blank"><img src="${NationalEmblem}" class="emblem">苏公网安备32102302010258号</a>\n` +
    '<a href="https://beian.miit.gov.cn/" target="_blank">津ICP备20005772号-1</a>\n' +
    `<span>&copy; Yukai Miao, ${new Date().getFullYear()}</span>\n`;
  document.body.insertAdjacentElement("afterbegin", ele);

  ele = document.createElement('div');
  ele.classList.add('vertical-center');
  ele.innerHTML = '    <div class="inner">\n' +
    '      <div class="cover">\n' +
    '        <div class="fade-in">' +
    '          <div class="logo">\n' +
    '            <div class="sub">为了备案我想了这样一个艺术气息扑面而来的适合抠脚程序员的名字</div>\n' +
    '            <div class="main"><span class="block">字节</span><span class="block"><span class="glow">记</span>忆</span></div>\n' +
    '          </div>\n' +
    '          <div class="content">' +
    '            <div class="title">宇锴的主页</div>' +
    '            <div class="social"></div>\n' +
    '          </div>' +
    '        </div>' +
    '      </div>' +
    '    </div>\n';
  document.body.insertAdjacentElement("afterbegin", ele);

  var social = ele.getElementsByClassName('social')[0];
  for (var i in socialLinks) {
    var socialLink = socialLinks[i];
    var link = document.createElement('a');
    if (socialLink.action) {
      link.addEventListener('click', function (_link) {
        return function (event) {
          event.stopPropagation();
          event.preventDefault();
          _link.action();
        }
      }(socialLink))
    }
    link.href = socialLink.url;
    link.classList.add('link');
    link.target = '_blank';
    link.title = socialLink.title;
    link.innerHTML = socialLink.icon;
    social.appendChild(link);
  }
}

function checkBrowser() {
  // check support of 'css animation' to decide if this browser is supported or not.
  var domPrefixes = 'Webkit Moz O ms Khtml'.split(' ');
  var elem = document.createElement('div');
  if (elem.style.animationName !== undefined) {
    return true;
  }
  for (var i = 0; i < domPrefixes.length; i++) {
    if (elem.style[domPrefixes[i] + 'AnimationName'] !== undefined) {
      return true
    }
  }
  return false;
}

function setupUpdateBrowser() {
  // add global flag
  document.getElementsByTagName('html')[0].className += ' not-supported';

  // add update browser hint
  var ele = document.createElement('div');
  ele.className = 'update-browser';
  ele.innerHTML = '本站点暂不支持您的浏览器版本，请先更新您的浏览器或使用其他浏览器访问。';
  document.body.insertAdjacentElement('afterbegin', ele);
}

function setup() {
  if (!checkBrowser()) {
    setupUpdateBrowser();
  } else {
    setupWebFonts();
    setupSiteIcons();
    setupMainContent();
    setupQRCode();
  }
}

setup();
